import React from 'react'
import { graphql } from 'gatsby'
import WatPage from 'src/components/WatPage'

import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import 'static/shared-style/normalize.css'
import 'static/shared-style/custom.scss'

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  return <WatPage wat={data.wat} />
}

export const pageQuery = graphql`
  query($path: String!) {
    wat: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        name
      }
    }
  }
`
